<template>
  <v-flex class="news-item" row>
    <v-flex xs1>
      <v-avatar :rounded="true" size="36px">
        <img :src="newsItem.image" class="news-image" />
      </v-avatar>
    </v-flex>
    <v-flex xs8 class="news-detail-container">
      <div class="news-source">
        {{ newsItem.source }}
      </div>
      <div class="news-body">{{ trimmedBody(newsItem.body) }}</div>
    </v-flex>
    <v-spacer />
    <v-flex xs1>
      <div class="news-source news-time">
        {{ getTime(newsItem) }}
      </div>
    </v-flex>
  </v-flex>
</template>

<script>
import moment from 'moment-timezone'
export default {
  props: {
    newsItem: { type: Object, default: () => {} },
  },
  methods: {
    trimmedBody(body) {
      if (body.length === 0) {
        return ''
      }
      return body.length < 400 ? body : body.substring(0, 400) + ' ...'
    },

    getTime(newsItem) {
      const now = moment()
      const newsTime = moment(newsItem.timestamp)
      const minutes = now.diff(newsTime, 'minutes')
      const hours = now.diff(newsTime, 'hours')
      const days = now.diff(newsTime, 'days')
      if (minutes > 59) {
        if (hours > 23) {
          return `${days}d`
        } else {
          return `${hours}h`
        }
      } else {
        return `${minutes}m`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.news-item {
  padding: 30px;
  border-bottom: 1px solid rgba(207, 203, 203, 0.5);
}

.news-time {
  float: right;
  align-content: top;
  margin-right: 12px;
}
.news-image {
  width: 22vw;
  border-radius: 50%;
}

.news-headline {
  color: #10161c;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-bottom: 10px;
  @media only screen and (max-width: 1200px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}
.news-source {
  display: flex;
  @media only screen and (max-width: 1200px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
  }
  color: #10161c;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 10px;
}
.news-body {
  @media only screen and (max-width: 1400px) {
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 18px;
  }
  color: #959ea9;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
  overflow: auto;
}

.news-detail-container {
  padding-left: 20px;
}

.news-time {
  margin-right: 12px;
}
</style>
