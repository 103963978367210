import auth from '@/state/utils/auth'
import axios from 'axios'

export const namespaced = true

export const state = {
  picks: [],
  topPerformers: [],
  chartData: undefined,
}

export const getters = {
  getPicks() {
    return state.picks
  },
  getTopPerformers() {
    return state.topPerformers
  },
}

export const mutations = {
  setTopPerformers(state, topPerformers) {
    state.topPerformers = topPerformers
  },
  setPicks(state, picks) {
    state.picks = picks
  },
  setChartData(state, chartData) {
    state.chartData = chartData
  },
}

export const actions = {
  async init({ commit }) {
    const profile = auth.getProfile(true)
    if (profile && profile.user) {
      commit('setCurrentUser', profile.user)
    }
  },
  async getPicks({ commit }) {
    const result = await axios.get('/v0/getChartInfoByUserPicks')
    commit('setPicks', result.data?.charts)
    return result.data?.charts
  },
  async getTopPerformers({ commit }) {
    const result = await axios.get('/v0/getTopPerformers')
    commit('setTopPerformers', result.data?.topPerformers)
    return result.data?.topPerformers
  },
  async getChartForRange({ commit }) {
    const data = {
      success: true,
      xAxis: ['Wednesday', 'Thursday', 'Friday', 'Monday', 'Tuesday'],
      yAxis: ['20.00%', '10.00%', '0.00%', '-10.00%', '-20.00%'],
      performance: [
        {
          _id: '601547e80fee8a14a752db61',
          positionId: '5f749d7e4b1c6228e16cd8f3',
          modelId: '5fc64ccf0c98f32a3081b887',
          accountNum: '496248188',
          userId: '5f47c870bc3f83fe3b27ed23',
          positionValueAmount: '667338.97',
          positionValuePercentage: '0.0629',
          timestamp: '2021-01-20T02:42:43.014Z',
          lastUpdated: '2021-01-30T02:42:43.014Z',
          lastUpdatedBy: 'performance-manager',
        },
        {
          _id: '601547e80fee8a14a752db60',
          positionId: '5f749d7e4b1c6228e16cd8f3',
          modelId: '5fc64ccf0c98f32a3081b887',
          accountNum: '496248188',
          userId: '5f47c870bc3f83fe3b27ed23',
          positionValueAmount: '667338.97',
          positionValuePercentage: '-11.0629',
          timestamp: '2021-01-21T02:42:43.014Z',
          lastUpdated: '2021-01-25T02:42:43.014Z',
          lastUpdatedBy: 'performance-manager',
        },
        {
          _id: '601547e80fee8a14a752db5f',
          positionId: '5f749d7e4b1c6228e16cd8f3',
          modelId: '5fc64ccf0c98f32a3081b887',
          accountNum: '496248188',
          userId: '5f47c870bc3f83fe3b27ed23',
          positionValueAmount: '667338.97',
          positionValuePercentage: '1.0629',
          timestamp: '2021-01-22T02:42:43.014Z',
          lastUpdated: '2021-01-26T02:42:43.014Z',
          lastUpdatedBy: 'performance-manager',
        },
        {
          _id: '601547e80fee8a14a752db5e',
          positionId: '5f749d7e4b1c6228e16cd8f3',
          modelId: '5fc64ccf0c98f32a3081b887',
          accountNum: '496248188',
          userId: '5f47c870bc3f83fe3b27ed23',
          positionValueAmount: '667338.97',
          positionValuePercentage: '5.0629',
          timestamp: '2021-01-25T02:42:43.014Z',
          lastUpdated: '2021-01-30T02:42:43.014Z',
          lastUpdatedBy: 'performance-manager',
        },
        {
          _id: '601547e80fee8a14a752db5d',
          positionId: '5f749d7e4b1c6228e16cd8f3',
          modelId: '5fc64ccf0c98f32a3081b887',
          accountNum: '496248188',
          userId: '5f47c870bc3f83fe3b27ed23',
          positionValueAmount: '667338.97',
          positionValuePercentage: '7.0629',
          timestamp: '2021-01-26T02:42:43.014Z',
          lastUpdated: '2021-01-30T02:42:43.014Z',
          lastUpdatedBy: 'performance-manager',
        },
      ],
    }
    commit('setChartData', data)
    return data
  },
  async isAuthenticated() {},
}
