<template>
  <div>
    <v-skeleton-loader class="wl-login-loader" type="avatar " />
    <h1 class="wl-login-loader">Logging in ...</h1>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
