<template>
  <div class="picks-container" id="picks-container">
    <div class="picks-header">Your Picks</div>
    <v-flex column>
      <div
        v-for="(pick, pickIdx) of picks"
        :key="`top-performer-pick-${pickIdx}`"
        :class="
          pickIdx === picks.length - 1
            ? 'pick-container-last'
            : 'pick-container'
        "
      >
        <PickContainer :pick="pick" size="small" />
      </div>
    </v-flex>
  </div>
</template>

<script>
import PickContainer from './PickContainer'

export default {
  components: {
    PickContainer,
  },
  props: {
    picks: { type: Array, default: () => [] },
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.picks-container {
  margin-top: 40px;
  border: 1px solid rgba(211, 211, 211, 0.3);
  border-radius: 8px;
}
.picks-header {
  margin-top: 10px;
  padding-left: 10px;
  color: #10161c;
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 32px;
}
.pick-container-last {
  @media only screen and (max-width: 600px) {
    width: 100px;
  }
  @media only screen and (max-width: 1200px) {
    width: 200px;
  }
  width: 94%;
  margin: 10px;
  padding: 20px;
  flex-shrink: 0;
  height: 100px;
}
.pick-container {
  @media only screen and (max-width: 600px) {
    width: 100px;
  }
  @media only screen and (max-width: 1200px) {
    width: 200px;
  }
  width: 94%;
  margin: 10px;
  padding: 20px;
  flex-shrink: 0;
  height: 100px;
  border-bottom: 1px solid rgb(211, 211, 211, 0.3);
}
</style>
