import axios from 'axios'

export const namespaced = true

export const state = {
  selectedModel: undefined,
  models: [],
  clients: [],
  chartData: {},
  modelPerformance: {}
}

export const getters = {
  getSelectedModel() {
    return state.selectedModel
  },
  getChartData() {
    return state.chartData
  },
  getModels() {
    return state.models
  },
  getModelPerformance() {
    return state.modelPerformance
  }
}

export const mutations = {
  setSelectedModel(state, selectedModel) {
    state.selectedModel = selectedModel
  },
  setModels(state, models) {
    state.models = models
  },
  setChartData(state, chartData) {
    state.chartData = chartData
  },
  setModelPerformance(state, modelPerformance) {
    state.modelPerformance = modelPerformance
  },
}

export const actions = {
  async init({ commit }) {
    await actions.getModels({ commit })
  },
  async getModels({ commit }) {
    const models = [
      {
        _id: '5fc64ccf0c98f32a3081b887',
        modelName: 'Alpha DNA',
      },
    ]
    commit('setModels', models)
    return models
  },
  async getChartData({ commit }, options) {
    const { modelId, userId, range = 'week'} = options
    const chartData = await axios.get(`/v0/model/chart/${modelId}/${userId}/${range}`)
    commit('setChartData', chartData?.data)
    return chartData?.data
  },
  async getModelPerformance({commit}, options) {
    const { modelId, userId } = options
    const performanceData = await axios.get(`/v0/model/performance/${modelId}/${userId}`)
    commit('setModelPerformance', performanceData?.data)
    return performanceData?.data 
  },
  async setSelectedModel({ commit }, modelId) {
    const model = state.models.find(m => m._id === modelId);
    commit('setSelectedModel', model)
  },
}
