<template>
  <v-card flat>
    <v-card-title>
      <v-flex row>
        <v-flex xs12 md8 lg8>
          <h3>Client Management</h3>
        </v-flex>
        <v-flex xs12 md4 lg4></v-flex>
      </v-flex>
    </v-card-title>
    <v-card-text>
      <v-flex row>
        <v-flex xs12>
          <ClientManagementTable />
        </v-flex>
      </v-flex>
      <v-flex row>
        <v-flex xs12></v-flex>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import ClientManagementTable from '@/components/ClientManagementTable'
export default {
  components: {
    ClientManagementTable,
  },
}
</script>

<style lang="scss" scoped></style>
