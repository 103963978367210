<template>
  <v-form ref="loginForm">
    <v-flex row wrap>
      <v-spacer class="disappearing-spacer" />
      <v-flex xs6 class="disappearing-hero">
        <v-flex align-self-end>
          <img class="login-hero" src="@/assets/Bot_Illustration.png" />
        </v-flex>
      </v-flex>
      <v-flex xs12 md5 lg5 layout column class="login-container">
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-flex layout column class="login-form">
          <div class="login-header">You've been invited!</div>
          <div class="login-input-container">
            <v-text-field
              v-model="email"
              class="login-input"
              label="Email"
              color="#0cb0c8"
              :autocomplete="false"
              :rules="[(v) => /.+@.+/.test(v) || 'Invalid Email address']"
              outlined
            ></v-text-field>
          </div>
          <div class="login-input-container">
            <v-text-field
              v-model="password"
              class="login-input"
              label="Password"
              color="#0cb0c8"
              autocomplete="false"
              outlined
              type="password"
              :rules="[(v) => (v || '').length > 0 || 'Password required']"
            ></v-text-field>
          </div>
          <div class="login-input-container">
            <v-text-field
              v-model="confirmPassword"
              class="login-input"
              label="Confirm Password"
              color="#0cb0c8"
              autocomplete="false"
              outlined
              type="password"
              :rules="[
                (v) =>
                  ((v || '').length > 0 && v === password) ||
                  'Passwords must match',
              ]"
              @keypress="onConfirmPasswordKeyPress"
            ></v-text-field>
          </div>
          <div class="login-input-container">
            <v-alert v-if="invitationSuccess" dense outlined type="success">
              Thank you for registering.
              <v-btn
                text
                outlined
                color="success"
                @click="$router.push({ name: 'login' })"
              >
                Login Here
              </v-btn>
            </v-alert>
            <div v-else>
              <v-alert
                v-if="typeof validInvitation === 'boolean' && !validInvitation"
                dense
                outlined
                type="error"
              >
                This invitation is no longer valid.
              </v-alert>
              <div v-else>
                <v-alert v-if="authError" dense outlined type="error">
                  Oops. There was a problem registering.
                </v-alert>

                <v-btn
                  v-show="typeof validInvitation === 'boolean'"
                  color="#0cb0c8"
                  class="white--text login-button"
                  x-large
                  depressed
                  :loading="loading"
                  @click="validateForm"
                >
                  Register
                </v-btn>
              </div>
            </div>
          </div>
        </v-flex>
        <v-spacer />
      </v-flex>
      <v-spacer />
    </v-flex>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import { authComputed } from '@/state/helper'
export default {
  data() {
    return {
      loading: false,
      password: undefined,
      confirmPassword: undefined,
      email: undefined,
      invitationId: false,
      authError: false,
      invitationSuccess: false,
      validInvitation: undefined,
    }
  },
  computed: {
    ...authComputed,
  },
  metaInfo: {
    title: `Bob - Invitation`,
  },
  async mounted() {
    this.invitationId = this.$route.params?.invitationId
    this.validInvitation = await this.confirmInvitation(this.invitationId)
  },
  methods: {
    ...mapActions({
      confirmInvitation: 'invitation/confirmInvitation',
      createUser: 'auth/createUser',
    }),
    onConfirmPasswordKeyPress(event) {
      if (event.key === 'Enter') {
        this.validateForm()
      }
    },
    async validateForm() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true
        const payload = {
          email: this.email,
          password: this.password,
          invitationId: this.invitationId,
        }
        const authResponse = await this.createUser(payload)
        if (authResponse === false) {
          this.authError = true
        } else {
          this.invitationSuccess = true
        }
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.disappearing-spacer {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

.disappearing-hero {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.login-hero {
  @media only screen and (max-width: 800px) {
    height: 100vh;
    width: 70vh;
  }
  width: 50vw;
}

.login-container {
  height: 70vh;
}

.login-form {
  align-items: center;
}

.login-input-container {
  width: 56%;
}

.login-remember-me {
  width: 56%;
  margin-top: -30px;
  margin-bottom: 30px;
}

.login-input {
  width: 100%;
}

.login-button {
  width: 100%;
}

.login-header {
  letter-spacing: 0.01em;
  font-size: 2.5em;
  font-weight: 500;
  margin-bottom: 50px;
}
</style>
