<template>
  <div>
    <h1>OOPS YOU ARE LOST!!</h1>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
