<template>
  <v-flex
    :aria-roledescription="`Click to view chart for ${pick.symbol} symbol`"
    role="button"
    row
    class="clickable-pick"
    @click="clickPick"
  >
    <v-flex :class="size === 'small' ? 'xs4 md8 lg8' : 'xs8 md8 lg8'">
      <div class="pick-symbol-header">{{ pick.symbol }}</div>
      <div :class="`pick-symbol-subheader-${size}`">
        {{ getCompanyName(pick.symbol) }}
      </div>
    </v-flex>
    <v-flex :class="size === 'small' ? 'xs3 md4 lg4' : 'xs4 md4 lg4'">
      <div class="pick-roi">{{ chartROI(pick) }}</div>
      <img :class="`pick-image-${size}`" :src="chartGraph(pick)" />
    </v-flex>
    <v-overlay v-if="showChart" @click.native="hideChart">
      <v-btn small fab class="close-chart" color="#0cb0c8" @click="hideChart">
        <v-icon dark>mdi-close</v-icon>
      </v-btn>
      <iframe :src="chartUrl" class="chart-window"></iframe>
    </v-overlay>
  </v-flex>
</template>

<script>
import GreenGraph from '@/assets/green-graph.svg'
import RedGraph from '@/assets/red-graph.svg'
import { mapGetters } from 'vuex'
// import axios from 'axios'
import auth from '@/state/utils/auth'
export default {
  data() {
    return {
      symbols: [],
      showChart: false,
      chartUrl: undefined,
    }
  },
  props: {
    pick: { type: Object, default: () => {} },
    size: { type: String, default: 'long' },
  },
  async mounted() {
    this.symbols = this.getSymbols()
  },

  methods: {
    ...mapGetters({
      getSymbols: 'symbol/getSymbols',
    }),
    getCompanyName(symbol) {
      return this.symbols.find((s) => symbol === s.value)?.text
    },
    chartROI(chart) {
      const { lastTradeRoi } = chart
      if (typeof lastTradeRoi === 'number') {
        return `${Number(lastTradeRoi).toFixed(2)}%`
      }
      return ''
    },
    chartGraph(pick) {
      const { lastTradeRoi } = pick
      if (typeof lastTradeRoi === 'number') {
        return lastTradeRoi > 0 ? GreenGraph : RedGraph
      }
      return GreenGraph
    },
    clickPick() {
      const { _id: chartId } = this.pick
      const baseUrl = 'https://beta.letbob.com' // axios.defaults.baseURL
      this.chartUrl = `${baseUrl}/chartiq/app.html#public=true&chartId=${chartId}&token=${auth.getToken()}`
      this.showChart = true
    },
    hideChart(e) {
      e.preventDefault()
      e.stopPropagation()
      this.showChart = false
    },
  },
}
</script>

<style lang="scss" scoped>
.chart-window {
  border-radius: 6px;
  border: 1px solid black;
  height: 60vh;
  width: 70vw;
}
.close-chart {
  position: fixed;
  top: 15vh;
  left: 85vw;
}
.clickable-pick {
  cursor: pointer;
}
.pick-image-long {
  color: red;
  width: 70px;
  margin-left: -6px;
}

.pick-image-small {
  @media only screen and (max-width: 600px) {
    display: none;
  }

  @media only screen and (max-width: 1200px) {
    width: 50px;
  }
  color: red;
  width: 70px;
  margin-left: -6px;
}
.pick-roi {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.pick-symbol-header {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.pick-symbol-subheader-long {
  color: #959ea9;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.pick-symbol-subheader-small {
  @media only screen and (max-width: 800px) {
    display: none;
  }
  @media only screen and (max-width: 1400px) {
    font-size: 12px;
    line-height: 18px;
  }
  color: #959ea9;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
}
</style>
