<template>
  <div>
    <div class="news-header">Latest News</div>
    <v-flex v-for="(newsItem, newsIdx) of news" :key="`news_item_${newsIdx}`">
      <GeneralNewsFeedItem
        v-if="['general'].includes(newsItem.newsType)"
        :newsItem="newsItem"
      />
      <SymbolNewsFeedItem
        v-if="['symbol'].includes(newsItem.newsType)"
        :newsItem="newsItem"
      />
      <SocialFeedItem
        v-if="['social'].includes(newsItem.newsType)"
        :newsItem="newsItem"
      />
    </v-flex>
    <v-flex xs12 align-content-center>
      <v-progress-linear
        v-show="loading"
        height="2"
        color="#0cb0c8"
        indeterminate
      />
    </v-flex>
    <v-btn
      v-show="showBackToTop"
      @click="backToTop"
      color="#0cb0c8"
      outlined
      class="back-to-top"
    >
      back to top
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SymbolNewsFeedItem from './SymbolNewsFeedItem'
import SocialFeedItem from './SocialFeedItem'
import GeneralNewsFeedItem from './GeneralNewsFeedItem'
export default {
  components: {
    GeneralNewsFeedItem,
    SymbolNewsFeedItem,
    SocialFeedItem,
  },
  data() {
    return {
      skip: -50,
      limit: 50,
      news: [],
      loading: true,
      stopGettingNews: false,
      showBackToTop: false,
    }
  },
  async mounted() {
    this.addScrollEventListener()
    await this.getMoreNews()
  },
  methods: {
    ...mapActions({
      getNews: 'news/getNews',
    }),
    async getMoreNews() {
      if (this.stopGettingNews) {
        return
      }

      this.loading = true
      this.skip = this.skip + this.limit
      const limit = this.limit
      const skip = this.skip
      const news = await this.getNews({ skip, limit })
      this.loading = false
      if (news.length) {
        this.news = this.news.concat(news)
      } else {
        this.stopGettingNews = true
        this.skip = 0
      }
    },
    backToTop() {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    },
    addScrollEventListener() {
      window.addEventListener(
        'scroll',
        async () => {
          const {
            scrollTop,
            scrollHeight,
            clientHeight,
          } = document.documentElement
          if (scrollTop >= clientHeight) {
            this.showBackToTop = true
          } else {
            this.showBackToTop = false
          }
          if (scrollTop + clientHeight >= scrollHeight - 5) {
            if (this.scrollDebounce) clearTimeout(this.scrollDebounce)
            this.scrollDebounce = setTimeout(async () => {
              await this.getMoreNews()
            }, 1000)
          }
        },
        {
          passive: true,
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.news-header {
  margin-top: 40px;
  padding-left: 8px;
  color: #10161c;
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 32px;
}

.back-to-top {
  position: fixed;
  left: 70vw;
  top: 90vh;
}
</style>
