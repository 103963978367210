<template>
  <v-card flat>
    <v-card-title>
      <v-flex row>
        <v-flex xs12 md8 lg8>
          <h3>Performance</h3>
        </v-flex>
        <v-flex xs12 md4 lg4>
          <ModelSelector />
        </v-flex>
      </v-flex>
    </v-card-title>
    <v-card-text>
      <v-flex row>
        <v-flex xs12 md8 lg8>
          <PerformanceChart />
        </v-flex>
        <v-flex xs12 md4 lg4>
          <ClientSelectorTable />
        </v-flex>
      </v-flex>
      <v-flex row>
        <v-flex xs12>
          <ModelPerformanceTable />
        </v-flex>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import PerformanceChart from '@/components/PerformanceChart'
import ModelPerformanceTable from '@/components/ModelPerformanceTable'
import ModelSelector from '@/components/ModelSelector'
import ClientSelectorTable from '@/components/ClientSelectorTable'
import { mapActions } from 'vuex'

export default {
  components: {
    PerformanceChart,
    ModelSelector,
    ModelPerformanceTable,
    ClientSelectorTable,
  },
  metaInfo: {
    title: `Bob - Model Performance`,
  },
  async mounted() {},
  methods: {
    ...mapActions({}),
  },
}
</script>

<style></style>
