<template>
  <v-form ref="accountNumForm">
    <v-card flat class="wl-page-module form-container">
      <v-card-title>
        <v-flex row>
          <v-flex xs12>
            <div class="form-header">Account Number</div>
          </v-flex>
          <v-flex
            xs12
            v-if="errorMessage || successfulLinking"
            class="form-header"
          >
            <v-alert v-if="errorMessage" type="error" dense dismissible>
              {{ errorMessage }}
            </v-alert>
            <v-alert v-if="successfulLinking" type="success" dense dismissible>
              Linking complete. One moment while we wrap up...
            </v-alert>
          </v-flex>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-subheader>
          Please provide account number to complete linking process.
        </v-subheader>
        <v-text-field
          v-model="accountNum"
          label="Account Number"
          :name="v4()"
          class="card-input"
          outlined
          :color="
            validAccountNum(accountNum)
              ? 'bobGreen'
              : accountNum
              ? 'error'
              : 'bobGreen'
          "
          :rules="accountNumRules"
          v-mask="['### ### ###']"
        />
      </v-card-text>
      <v-card-actions>
        <div class="action-container">
          <v-btn
            :disabled="!validAccountNum(accountNum)"
            :loading="linkingAccount"
            outlined
            color="bobGreen"
            @click="linkAccount"
          >
            Finish Linking
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mask } from 'vue-the-mask'
import { v4 } from 'uuid'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      v4,
      accountNum: undefined,
      linkingAccount: false,
      successfulLinking: false,
      errorMessage: undefined,
    }
  },
  directives: {
    mask,
  },
  computed: {
    ...mapGetters({
      code: 'broker/getCode',
    }),
    accountNumRules() {
      return [this.validAccountNum(this.accountNum) || 'Invalid account number']
    },
  },
  methods: {
    ...mapActions({
      getToken: 'broker/getToken',
      updateAccountNumber: 'broker/updateAccountNumber',
    }),
    makeString: (v) => (v || '').replace(/ /g, '').replace(/\//g, ''),
    validAccountNum(v) {
      v = this.makeString(v)
      if (v.length === 9) {
        return true
      }
      return false
    },
    async linkAccount() {
      this.linkingAccount = true
      try {
        await this.updateAccountNumber({
          accountNum: this.makeString(this.accountNum),
        })
        await this.getToken(this.code)
        this.successfulLinking = true
        setTimeout(() => {
          this.$emit('saved')
        }, 5000)
      } catch (e) {
        this.errorMessage = 'Unable to complete linking'
        this.linkingAccount = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.form-container {
  background-color: rgb(0, 0, 0, 0.9);
}

.card-input {
  padding: 4px;
}

.form-header {
  padding-left: 20px;
}

.action-container {
  text-align: center;
  width: 100%;
  padding-right: 20px;
  padding-bottom: 10px;
}
</style>
