import axios from 'axios'

export const namespaced = true

export const state = {
  news: [],
  charts: [],
}

export const getters = {
  getPicks() {
    return state.picks
  },
  getCharts() {
    return state.charts
  },
  getCurrentUserInitials() {
    return state.currentUser
  },
}

export const mutations = {
  setPicks(state, picks) {
    state.picks = picks
  },
  setCharts(state, charts) {
    state.charts = charts
  },
}

export const actions = {
  async getNews(store, { skip, limit = 20 }) {
    const chartsResult = await axios.get(`/v0/news?skip=${skip}&limit=${limit}`)
    return chartsResult.data?.news || []
  },
  async isAuthenticated() {},
}
