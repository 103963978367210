<template>
  <v-form ref="loginForm">
    <v-flex row wrap>
      <v-spacer class="disappearing-spacer" />
      <v-flex xs6 class="disappearing-hero">
        <v-flex align-self-end>
          <img class="login-hero" src="@/assets/Bot_Illustration.png" />
        </v-flex>
      </v-flex>
      <v-flex xs12 md5 lg5 layout column class="login-container">
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-flex layout column class="login-form">
          <div class="login-header">Forgot Password</div>
          <div class="login-input-container">
            <v-text-field
              v-model="email"
              class="login-input"
              label="Email"
              color="#0cb0c8"
              :autocomplete="false"
              :rules="[(v) => /.+@.+/.test(v) || 'Invalid Email address']"
              outlined
            ></v-text-field>
          </div>
          <div class="login-input-container">
            <v-alert v-if="authError" dense outlined type="error">
              Oops. There was a problem sending request to reset password.
            </v-alert>
            <v-alert v-else-if="requestSuccess" dense outlined type="success">
              Please check your email for a link to reset your password
            </v-alert>
            <v-btn
              v-else
              color="#0cb0c8"
              class="white--text login-button"
              x-large
              depressed
              :loading="loading"
              @click="validateForm"
            >
              Request Password Reset
            </v-btn>
          </div>
        </v-flex>
        <v-spacer />
      </v-flex>
      <v-spacer />
    </v-flex>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { authComputed } from '@/state/helper'
export default {
  data() {
    return {
      showPassword: false,
      loading: false,
      password: undefined,
      email: undefined,
      rememberMe: undefined,
      authError: false,
      requestSuccess: false,
    }
  },
  computed: {
    ...authComputed,
  },
  metaInfo: {
    title: `Bob - Login`,
  },
  mounted() {
    const remember = this.getRememberMe()
    if (remember) {
      const { email } = this.currentUser
      if (email) {
        this.email = email
      }
    }
    this.rememberMe = remember
  },
  methods: {
    ...mapGetters({
      getRememberMe: 'auth/getRememberMe',
    }),
    ...mapMutations({
      setRememberMe: 'auth/setRememberMe',
    }),
    ...mapActions({
      sendResetPassword: 'auth/sendResetPassword',
    }),
    onPasswordKeyPress(event) {
      if (event.key === 'Enter') {
        this.validateForm()
      }
    },
    async validateForm() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true
        const payload = {
          email: this.email,
        }
        const sendResetPasswordResponse = await this.sendResetPassword(payload)
        if (sendResetPasswordResponse === false) {
          this.authError = true
          setTimeout(() => (this.authError = false), 10000)
        } else {
          this.authError = false
          this.requestSuccess = true
        }
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.disappearing-spacer {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

.disappearing-hero {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.login-hero {
  @media only screen and (max-width: 800px) {
    height: 100vh;
    width: 70vh;
  }
  width: 50vw;
}

.login-container {
  height: 70vh;
}

.login-form {
  align-items: center;
}

.login-input-container {
  width: 56%;
}

.login-remember-me {
  width: 56%;
  margin-top: -30px;
  margin-bottom: 30px;
}

.login-input {
  width: 100%;
}

.login-button {
  width: 100%;
}

.login-header {
  letter-spacing: 0.01em;
  font-size: 2.5em;
  font-weight: 500;
  margin-bottom: 50px;
}
</style>
