<template>
  <v-card>
    <v-navigation-drawer
      absolute
      left
      sit-above
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-icon>mdi-menu</v-icon>
        </v-list-item-avatar>

        <v-list-item-title style="text-align:center;">
          <img
            src="@/assets/boblogosmall.png"
            class="main-logo"
            @click="$router.push({ name: 'advisorHome' })"
          />
        </v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content @click="() => route(item)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      mini: true,
      items: [
        {
          title: 'Performance',
          icon: 'mdi-chart-line',
          route: 'clientPerformance',
        },
        {
          title: 'Account',
          icon: 'mdi-account',
        },
      ],
    }
  },
  methods: {
    route(item) {
      this.mini = !this.mini
      if (item.route) {
        this.$router.push({ name: item.route })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main-logo {
  margin-top: 18px;
  height: 26px;
  cursor: pointer;
}
.sit-above {
  z-index: 99;
}
.v-navigation-drawer {
  height: 100vh;
  position: fixed;
  top: 0;
  width: 60vw;
  right: 0;
}
</style>
