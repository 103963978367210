export const GlobalSearchResultColumns = {
  funds: [
    {
      text: 'Fund',
      value: 'name',
    },
    {
      text: 'Total Clients',
      value: 'totalClients',
    },
    {
      text: 'Total Holdings Value',
      value: 'totalSecuritiesValue',
    },
  ],
  clients: [
    {
      text: 'First',
      value: 'firstName',
    },
    {
      text: 'Last',
      value: 'lastName',
    },
    {
      text: 'Fund',
      value: 'fundName',
    },
    {
      text: 'Account',
      value: 'accountNum',
    },
  ],
  models: [
    {
      text: 'Model',
      value: 'strategy',
    },
  ],
}

export const ModelClientColumns = [
  {
    text: 'Client Name',
    value: 'name',
  },
  {
    text: 'Account',
    value: 'accountNum',
  },
  {
    text: 'Model Position Value',
    value: 'totalModelValue',
  },
  {
    text: 'Fund',
    value: 'fundName',
  },
]

export const ModelFundColumns = [
  {
    text: 'Fund',
    value: 'name',
  },
  {
    text: 'Total Clients',
    value: 'totalClients',
  },
  {
    text: 'Total Clients',
    value: 'totalSecuritiesValue',
  },
]

export const ClientPositionColumns = [
  {
    text: 'Symbol',
    value: 'symbol',
  },
  {
    text: 'Shares',
    value: 'shares',
  },
  {
    text: 'New Investment Opportunity',
    value: 'cashBalance',
  },
  // {
  //   text: 'Model Balance Standing',
  //   value: 'modelBalance',
  // },
  {
    text: 'Model',
    value: 'modelName',
  },
  {
    text: 'Fund',
    value: 'fundName',
  },
]

export const AdvisorTradeReviewColumns = [
  {
    text: 'Select',
    value: 'select',
  },
  {
    text: 'Fund',
    value: 'fundName',
  },
  {
    text: 'Model',
    value: 'modelName',
  },
  {
    text: 'Buy/Sell',
    value: 'side',
  },
  {
    text: 'Symbol',
    value: 'security',
  },
  {
    text: 'Shares',
    value: 'totalQuantity',
  },
  {
    text: 'Price',
    value: 'price',
  },
  {
    text: 'Actions',
    value: 'actions',
  },
]

export const AdvisorTradeReviewAccountColumns = [
  {
    text: 'First Name',
    value: 'firstName',
  },
  {
    text: 'Last Name',
    value: 'lastName',
  },
  {
    text: 'Account',
    value: 'accountNum',
  },
  {
    text: 'Shares',
    value: 'quantity',
  },
  {
    text: 'Price',
    value: 'price',
  },
]

export const ModelPerformanceColumns = [
  {
    text: 'Symbol',
    value: 'symbol',
  },
  {
    text: 'Description',
    value: 'companyName',
  },
  {
    text: 'Qty',
    value: 'shares',
  },
  {
    text: 'Open Date',
    value: 'openDate',
  },
  {
    text: 'Cost',
    value: 'cost',
  },
  {
    text: 'Close Date',
    value: 'closeDate',
  },
  {
    text: 'Current Value',
    value: 'currentValue',
  },
  {
    text: 'Gain',
    value: 'gain',
  },
  {
    text: 'Gain (%)',
    value: 'gainPercentage',
  },
]

export const ClientManagementTableColumns = [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Account Number',
    value: 'accountNum',
  },
  {
    text: 'Cash',
    value: 'cash',
  },
  {
    text: 'Cash Allocation',
    value: 'cashAllocation',
  },
  {
    text: 'Expand',
    value: 'actions',
  },
]
