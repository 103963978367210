var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"wl-page-module",attrs:{"loading":_vm.loading,"fill-height":"","flat":""}},[_c('v-card-title',[_vm._v("Clients")]),(_vm.loading)?_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})],1):_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Search Clients","outlined":"","color":"bobGreen"},on:{"keydown":_vm.initSearch},model:{value:(_vm.searchClientsValue),callback:function ($$v) {_vm.searchClientsValue=$$v},expression:"searchClientsValue"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clients,"search":_vm.searchClients,"page":_vm.page,"expanded":_vm.expanded},on:{"click:row":_vm.toggleRowExpansion},scopedSlots:_vm._u([{key:"item.cashAllocation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cash-allocation-container"},[_c('v-text-field',{ref:("cash_input_" + (_vm.clients.findIndex(function (f) { return f === item; }))),attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-currency-usd","color":"bobGreen","tabindex":"1"},on:{"click":function (e) { return _vm.setFocus(
                  e,
                  _vm.clients.findIndex(function (f) { return f === item; })
                ); },"keydown":_vm.guardNumber},model:{value:(item.newCashAllocation),callback:function ($$v) {_vm.$set(item, "newCashAllocation", $$v)},expression:"item.newCashAllocation"}})],1)]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","icon":""},on:{"click":function () { return item; }}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}},{key:"expanded-item",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"expanded-client",attrs:{"colspan":_vm.headers.length}},[_c('ClientManagementDetail',{attrs:{"client":item}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }