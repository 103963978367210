import axios from 'axios'

export const namespaced = true

export const state = {
  symbols: [],
}

export const getters = {
  getSymbols() {
    return state.symbols
  },
}

export const mutations = {
  setSymbols(state, symbols) {
    state.symbols = symbols.map((s) => ({
      text: s.name ? `${s.symbol} - ${s.name}` : s.symbol,
      value: s.symbol,
    }))
  },
}

export const actions = {
  // async init({ commit }) {
  //   await actions.getSymbols({ commit })
  // },
  async getSymbols({ commit }) {
    const symbols = await axios
      .get('https://api.iextrading.com/1.0/ref-data/symbols', {
        transformRequest: (data, headers) => {
          delete headers.common['Authorization']
        },
      })
      .catch(() => false)
    if (symbols.data) {
      commit('setSymbols', symbols.data)
    }
  },
}
