<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Bob',
  },
  data() {
    return {
      menu: false,
    }
  },
}
</script>
<style lang="scss">
@import '@/styles/variables.scss';
</style>
