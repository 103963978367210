<template>
  <div>
    <RetailSideMenu class="appearing-menu" />
    <v-app-bar color="white" flat>
      <v-flex row>
        <v-flex row class="appearing-menu">
          <v-spacer />
          <v-flex xs1>
            <img
              src="@/assets/boblogosmall.png"
              class="main-logo"
              @click="$router.push({ name: 'retailHome' })"
            />
          </v-flex>
          <v-spacer />
        </v-flex>
        <v-flex class="disappearing-spacer" xs1 md2 lg2 />
        <v-flex class="disappearing-spacer" row xs10 md8 lg8>
          <img
            src="@/assets/boblogosmall.png"
            class="main-logo"
            @click="$router.push({ name: 'retailHome' })"
          />
          <v-spacer class="disappearing-spacer" />

          <div
            class="menu-item"
            aria-roledescription="View performance"
            role="button"
            @click="$router.push({ name: 'clientPerformance' })"
          >
            Performance
          </div>

          <div
            class="menu-item"
            aria-roledescription="View account"
            role="button"
            @click="$router.push({ name: 'clientAccount' })"
          >
            Account
          </div>
        </v-flex>
        <v-flex class="disappearing-spacer" xs1 md2 lg2 />
      </v-flex>
    </v-app-bar>

    <v-flex row>
      <v-flex class="disappearing-spacer" xs1 />
      <v-flex row xs12 md10 lg10>
        <hr class="bar-footer" />
      </v-flex>
      <v-flex class="disappearing-spacer" xs1 />
    </v-flex>
    <v-flex row>
      <v-flex xs2 />
      <v-flex xs8>
        <router-view />
      </v-flex>
      <v-flex xs2 />
    </v-flex>
  </div>
</template>

<script>
import { authComputed } from '@/state/helper'
import { mapGetters, mapActions } from 'vuex'
import RetailSideMenu from '@/components/RetailSideMenu'
export default {
  components: { RetailSideMenu },
  data() {
    return {
      searchExpanded: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      showResultsPanel: 'search/getShowResultsPanel',
      showAdvisorProfileMenu: 'profileMenu/getShowAdvisorProfileMenu',
    }),
  },
  methods: {
    ...mapActions({
      setShowAdvisorProfileMenu: 'profileMenu/setShowAdvisorProfileMenu',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.disappearing-spacer {
  @media only screen and (max-width: 690px) {
    display: none;
  }
}

.appearing-menu {
  @media only screen and (min-width: 690px) {
    display: none;
  }
}

.main-logo {
  margin-top: 18px;
  height: 36px;
  cursor: pointer;
}

.bar-footer {
  margin-left: 20px;
  opacity: 0.15;
  align-self: center;
  width: 100vw;
  @media only screen and (max-width: 690px) {
    width: 100vw;
  }
}

.menu-item {
  color: rgb(173, 168, 168);
  font-weight: 500;
  padding: 10px;
  margin-top: 16px;
}
</style>
