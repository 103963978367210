<template>
  <v-form ref="loginForm">
    <v-flex row wrap>
      <v-spacer class="disappearing-spacer" />
      <v-flex xs6 class="disappearing-hero">
        <v-flex align-self-end>
          <img class="login-hero" src="@/assets/bob.gif" />
        </v-flex>
      </v-flex>
      <v-flex xs12 md5 lg5 layout column class="login-container">
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-flex layout column class="login-form">
            <div>
            <img class="bob-logo" src="@/assets/bobgreenlogo.png" />
            </div>
          <div class="login-header">Bob Welcome's You!</div>
          <div class="login-input-container">
            <v-alert v-if="invitationSuccess" dense outlined type="success">
              Thank you for verifying your Email.
            </v-alert>
            <div v-else>
              <v-alert v-if="authError" dense outlined type="error">
                Oops. There was a problem verifying your email.
              </v-alert>

              <v-btn
                color="#0cb0c8"
                class="white--text login-button"
                x-large
                depressed
                :loading="loading"
                @click="validateForm"
              >
                Verify Email
              </v-btn>
            </div>
          </div>
        </v-flex>
        <v-spacer />
      </v-flex>
      <v-spacer />
    </v-flex>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import { authComputed } from '@/state/helper'
export default {
  data() {
    return {
      loading: false,
      email: undefined,
      verifyEmailId: false,
      invitationSuccess: false,
    }
  },
  computed: {
    ...authComputed,
  },
  metaInfo: {
    title: `Bob - Reset Password`,
  },
  async mounted() {
    this.verifyEmailId = this.$route.params?.verifyEmailId
  },
  methods: {
    ...mapActions({
      verifyEmail: 'auth/verifyEmail',
    }),
    onConfirmPasswordKeyPress(event) {
      if (event.key === 'Enter') {
        this.validateForm()
      }
    },
    async validateForm() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true
        const payload = {
          email: this.email,
          password: this.password,
          verifyEmailId: this.verifyEmailId,
        }
        const authResponse = await this.verifyEmail(payload)
        if (authResponse === false) {
          this.authError = true
        } else {
          this.invitationSuccess = true
        }
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.disappearing-spacer {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

.disappearing-hero {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.login-hero {
  @media only screen and (max-width: 800px) {
    height: 100vh;
    width: 70vh;
  }
  width: 50vw;
}
.bob-logo {
  @media only screen and (max-width: 960px) {
    width: 20vh;
  }
  width: 10vw;
}
.login-container {
  height: 70vh;
}

.login-form {
  align-items: center;
}

.login-input-container {
  width: 56%;
}

.login-remember-me {
  width: 56%;
  margin-top: -30px;
  margin-bottom: 30px;
}

.login-input {
  width: 100%;
}

.login-button {
  width: 100%;
}

.login-header {
  letter-spacing: 0.01em;
  font-size: 2.5em;
  font-weight: 500;
  margin-bottom: 50px;
}
</style>
