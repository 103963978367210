<template>
  <div>
    <v-skeleton-loader class="wl-login-loader" type="avatar " />
    <h1 class="wl-login-loader">Linking Brokerage ...</h1>
    <v-progress-linear height="2" indeterminate />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      callbackRoute: 'broker/getCallbackRoute',
    }),
  },
  async mounted() {
    const code = location.href.split('?')[1].split('=')[1]
    this.setCode(code)
    this.setSuccessfulLink(true)
    console.log('this.callbackRoute', this.callbackRoute)
    this.$router.push({ name: this.callbackRoute })
  },
  methods: {
    ...mapMutations({
      setCode: 'broker/setCode',
      setSuccessfulLink: 'broker/setSuccessfulLink',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
