<template>
  <v-form ref="cardPaymentForm">
    <v-card flat class="wl-page-module credit-card-form-container">
      <v-card-title>
        <v-flex row>
          <v-flex xs2>
            <div class="credit-card-form-header">Payment</div>
          </v-flex>
          <v-flex xs8>
            <v-alert v-if="errorMessage" type="error" dense dismissible>
              {{ errorMessage }}
            </v-alert>
            <v-alert
              v-if="paymentDetailSuccess"
              type="success"
              dense
              dismissible
            >
              Payment saved.
            </v-alert>
          </v-flex>
          <v-flex xs2>
            <div class="close-form-container">
              <v-btn fab outlined x-small @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-flex>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-flex row>
          <v-flex xs2 />
          <v-flex xs8>
            <v-text-field
              v-model="name"
              class="card-input"
              :name="v4()"
              autocomplete="false"
              outlined
              label="Name On Card"
              :color="
                makeString(name).length > 1
                  ? 'bobGreen'
                  : name
                  ? 'error'
                  : 'bobGreen'
              "
              :rules="[
                () => makeString(name).length > 1 || 'Name must be provided',
              ]"
            />
          </v-flex>
          <v-flex xs2 />
          <v-flex xs2 />
          <v-flex xs8>
            <v-text-field
              v-model="card"
              class="card-input"
              outlined
              :name="v4()"
              label="Credit Card Number"
              :color="
                validateCard(card) ? 'bobGreen' : card ? 'error' : 'bobGreen'
              "
              :rules="creditCardNumberRules"
              :prepend-inner-icon="
                validateCard(card)
                  ? cardIcon
                  : card
                  ? 'mdi-credit-card-off-outline'
                  : 'mdi-credit-card-outline'
              "
              v-mask="[' ####  ####  ####  ####', ' ####  ####  #####  ###']"
            />
          </v-flex>
          <v-flex xs2 />
          <v-flex xs2 />
          <v-flex xs3>
            <v-text-field
              v-model="exp"
              label="Expiration"
              placeholder="12 / 22"
              :name="v4()"
              class="card-input"
              outlined
              :color="
                validateExp(exp) ? 'bobGreen' : exp ? 'error' : 'bobGreen'
              "
              :rules="creditCardExpRules"
              v-mask="['## / ##']"
            />
          </v-flex>
          <v-flex xs2 />
          <v-flex xs3>
            <v-text-field
              v-model="cvv"
              label="CVV"
              :name="v4()"
              class="card-input"
              outlined
              :color="
                validateCvv(cvv) ? 'bobGreen' : cvv ? 'error' : 'bobGreen'
              "
              :rules="creditCardCvvRules"
              v-mask="['###']"
            />
          </v-flex>
          <v-flex xs2 />
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <div class="credit-card-action-container">
          <v-btn
            :disabled="!cardFormValid"
            :loading="savingPayment"
            outlined
            color="bobGreen"
            @click="savePaymentDetail"
          >
            Save Payment
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import moment from 'moment-timezone'
import { mask } from 'vue-the-mask'
import { v4 } from 'uuid'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      v4,
      card: undefined,
      exp: undefined,
      cvv: undefined,
      name: undefined,
      savingPayment: false,
      errorMessage: undefined,
      paymentDetailSuccess: false,
      iconMap: {
        mastercard: 'fab fa-cc-mastercard',
        visa: 'fab fa-cc-visa',
        discover: 'fab fa-cc-discover',
        amex: 'fab fa-cc-amex',
        diners: 'fab fa-cc-diners-club',
        default: 'mdi-credit-card-check-outline',
      },
    }
  },
  directives: {
    mask,
  },
  computed: {
    cardFormValid() {
      return (
        this.validateCard(this.card) &&
        this.validateExp(this.exp) &&
        this.validateCvv(this.cvv) &&
        this.makeString(this.name).length > 1
      )
    },
    creditCardNumberRules() {
      return [(v) => this.validateCard(v) || 'Invalid card number']
    },
    creditCardExpRules() {
      return [(v) => this.validateExp(v) || 'Invalid card expiration']
    },
    creditCardCvvRules() {
      return [(v) => this.validateCvv(v) || 'Invalid cvv']
    },
    cardIcon() {
      return this.iconMap[this.cardType]
    },
    cardType() {
      const card = this.makeString(this.card)
      if (card?.[0] === '5') {
        return 'mastercard'
      } else if (card?.[0] === '4') {
        return 'visa'
      } else if (card?.[0] === '6') {
        return 'discover'
      } else if (['34', '37'].includes(card?.substring(0, 2))) {
        return 'amex'
      } else if (['30', '36', '38'].includes(card?.substring(0, 2))) {
        return 'diners'
      } else {
        return 'default'
      }
    },
  },
  methods: {
    ...mapActions({
      savePayment: 'payment/savePayment',
    }),
    makeString: (v) => (v || '').replace(/ /g, '').replace(/\//g, ''),
    validateCard(v) {
      const trimmed = this.makeString(v)
      if (this.cardType === 'amex') {
        return [15, 16].includes(trimmed.length)
      } else {
        return [16].includes(trimmed.length)
      }
    },
    validateCvv(v) {
      return [3].includes(this.makeString(v).length)
    },
    validateExp(v) {
      const trimmed = this.makeString(v)
      if ([4].includes(trimmed.length)) {
        const thisYear = parseInt(moment().format('YY'), 10)
        const yearValue = parseInt(trimmed.substring(2, 4), 10)
        const thisMonth = parseInt(moment().format('MM'), 10)
        const monthValue = parseInt(trimmed.substring(0, 2), 10)
        if (thisYear > yearValue) {
          return false
        }
        if (thisYear === yearValue) {
          if (thisMonth > monthValue) {
            return false
          }
        }
        if (monthValue > 12) {
          return false
        }
        return true
      }
      return false
    },
    async savePaymentDetail() {
      this.errorMessage = undefined
      const paymentDetail = {
        number: this.makeString(this.card),
        cvc: this.cvv,
        expiry: this.makeString(this.exp),
        cardType: this.cardType,
        name: this.name,
      }
      this.savingPayment = true
      const cardResult = await this.savePayment(paymentDetail)
      if (cardResult?.error) {
        this.errorMessage =
          'There was a problem with your card details. Please verify and try again.'
      } else {
        this.paymentDetailSuccess = true
        setTimeout(() => {
          this.$emit('saved')
        }, 10000)
      }
      this.savingPayment = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.credit-card-form-container {
  // width: 80%;
  background-color: rgb(0, 0, 0, 0.9);
}

.card-input {
  padding: 4px;
}

.close-form-container {
  display: flex;
  float: right;
}

.credit-card-form-header {
  padding-left: 20px;
}

.credit-card-action-container {
  text-align: center;
  width: 100%;
  padding-right: 20px;
  padding-bottom: 10px;
}
</style>
