import axios from 'axios'

class Auth {
  async login() {}

  getToken() {
    return localStorage.getItem('token')
  }

  setToken(token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    localStorage.setItem('token', token)
  }

  setProfile(user) {
    localStorage.setItem('profile', JSON.stringify(user))
  }

  getProfile() {
    const profileString = localStorage.getItem('profile')
    return JSON.parse(profileString)
  }

  getRememberMe() {
    const rememberMe = localStorage.getItem('rememberMe')
    return JSON.parse(rememberMe)
  }

  setRememberMe(value) {
    localStorage.setItem('rememberMe', JSON.stringify(value))
  }

  isAuthenticated() {
    const token = localStorage.getItem('token')
    if (token === 'null' || !token) {
      return false
    }
    return true
  }

  logout() {
    localStorage.removeItem('token')
  }

  async getUser() {
    const response = await axios.get('/v0/user.getUser')
    this.setProfile(response?.data?.user)
    return response?.data?.user
  }
}

export default new Auth()
