import axios from 'axios'

export const namespaced = true

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async confirmInvitation(_, invitationId) {
    const payload = {
      invitationId,
    }
    const confirmationResponse = await axios
      .post('/v0/confirmInvitation', payload)
      .catch((e) => e.response)
    if (confirmationResponse?.status !== 200) {
      return false
    }
    return true
  },
}
