<template>
  <v-card :loading="loading" fill-height flat class="wl-page-module">
    <v-card-title>
      Clients
      <v-spacer />
      <v-chip
        v-if="client"
        close
        color="#0cb0c8"
        outlined
        @click:close="() => selectClient(undefined)"
      >
        <small>Viewing {{ client.name }}</small>
      </v-chip>
    </v-card-title>
    <v-card-text v-if="loading">
      <v-skeleton-loader type="table-tbody" />
    </v-card-text>
    <v-card-text v-else>
      <v-flex row>
        <v-flex xs12 class="client-data-search">
          <v-text-field
            label="Search Clients"
            v-model="searchClientValue"
            outlined
            dense
            color="bobGreen"
            @keydown="initSearch"
          />
        </v-flex>
      </v-flex>
      <hr v-show="!searchingClients" class="bar-footer" />
      <v-progress-linear v-show="searchingClients" height="1" indeterminate />
      <div class="client-list-container">
        <div
          v-for="(client, clientIndex) of filteredClients"
          :key="`managed_client_${clientIndex}`"
          class="client-container"
          @click="() => selectClient(client)"
        >
          {{ client.name }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatPercent } from '@/utils/number'
import currencyFilter from '@/utils/currency'
import op from 'simple-object-path'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { authComputed } from '@/state/helper'

export default {
  computed: {
    ...mapGetters({
      managedClients: 'client/getManagedClients',
      client: 'client/getClient',
    }),
    ...authComputed,
  },
  data() {
    return {
      op,
      formatPercent,
      currencyFilter,
      positions: [],
      headers: [{ text: 'Name', value: 'name' }],
      filteredClients: [],
      searchClientValue: undefined,
      searchClients: undefined,
      page: 1,
      loading: false,
      searchingClients: false,
    }
  },
  async mounted() {
    this.loading = true
    this.filteredClients = await this.getManagedClients()
    this.loading = false
  },
  methods: {
    ...mapActions({
      getManagedClients: 'client/getManagedClients',
    }),
    ...mapMutations({
      setClient: 'client/setClient',
    }),
    initSearch($event) {
      if (!this.searchClientValue) return
      this.searchingClients = true
      this.debounce = setTimeout(() => {
        this.searchClients = this.searchClientValue
        this.filterClients()
        this.searchingClients = false
      }, 1000)
      if ($event.key === 'Enter') {
        this.searchClients = this.searchClientValue
        this.filterClients()
        this.searchingClients = false
        clearTimeout(this.debounce)
      }
    },
    filterClients() {
      console.log(this.searchClientValue, this.searchClients)
      this.filteredClients = this.managedClients.filter((c) =>
        (c.name || '')
          .toUpperCase()
          .includes((this.searchClientValue || '').toUpperCase())
      )
    },
    selectClient(client) {
      this.setClient(client)
      this.filteredClients = this.managedClients
      this.searchClientValue = undefined
      this.searchClient = undefined
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.client-list-container {
  overflow: scroll;
  max-height: 140px;
}

.client-data-search {
  margin: 10px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 20px;
  max-height: 40px;
}

.bar-footer {
  opacity: 0.15;
  align-self: center;
  width: 100%;
}
.client-container {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 20px;
  max-height: 40px;
  &:hover {
    background: rgb(12, 176, 200, 0.6);
    color: white;
  }
}
</style>
