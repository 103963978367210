<template>
  <v-card :loading="loading" fill-height flat class="wl-page-module">
    <v-card-title>
      Level Chart
      <v-spacer />
      <div class="chart-range-container">
        <div
          v-for="(chartRange, chartRangeIndex) of chartRanges"
          :key="`chart_range_item_${chartRangeIndex}`"
        >
          <div
            :class="{
              'chart-range-item disabled': chartRange.disabled,
              'chart-range-item active': chartRange !== selectedChartRange,
              'chart-range-item active selected':
                chartRange === selectedChartRange,
            }"
            @click="() => selectChartRange(chartRange)"
          >
            {{ chartRange.label }}
          </div>
        </div>
      </div>
    </v-card-title>
    <v-card-text v-if="loading">
      <v-skeleton-loader type="card" />
    </v-card-text>
    <v-card-text v-if="!loading && chartDataPoints.length > 0">
      <GChart
        type="AreaChart"
        :options="chartOptions"
        :data="chartDataPoints"
      />
    </v-card-text>
    <v-card-text v-if="!loading && chartDataPoints.length === 0">
      No data available.
    </v-card-text>
  </v-card>
</template>

<script>
import { GChart } from 'vue-google-charts'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import { authComputed } from '@/state/helper'

export default {
  components: {
    GChart,
  },
  data() {
    return {
      loading: true,
      chartOptions: {},
      chartDataPoints: [],
      selectedChartRange: undefined,
      chartRanges: [
        {
          label: '1D',
          value: '1DAY',
          disabled: true,
        },
        {
          label: '5D',
          value: '5DAY',
          disabled: false,
        },
        {
          label: '1W',
          value: 'WEEK',
          disabled: false,
        },
        {
          label: '1M',
          value: 'MONTH',
          disabled: true,
        },
        {
          label: '6M',
          value: '6MONTH',
          disabled: true,
        },
        {
          label: '1M',
          value: '6YEAR',
          disabled: true,
        },
        {
          label: 'YTD',
          value: 'YTD',
          disabled: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      selectedModel: 'performance/getSelectedModel',
      getChartData: 'performance/getChartData',
      client: 'client/getClient',
    }),
    ...authComputed,
  },
  watch: {
    async selectedModel() {
      await this.getRawChartData(this.client)
    },
    async getChartData(value) {
      this.convertRawChartData(value)
    },
    async client() {
      await this.getRawChartData(this.client)
    },
    async selectedChartRange() {
      await this.getRawChartData(this.client)
    },
  },
  async mounted() {
    this.selectedChartRange = this.chartRanges.find((r) => r.label === '5D')
    this.loading = false
  },
  methods: {
    ...mapActions({
      loadChartData: 'performance/getChartData',
      getModelPerformance: 'performance/getModelPerformance',
    }),
    async getRawChartData(client) {
      if (!this.selectedModel) {
        return
      }
      this.loading = true
      let userId = this.currentUser?._id
      if (client) {
        userId = client.id
      }
      const { _id: modelId } = this.selectedModel
      const range = this.selectedChartRange.value
      await this.loadChartData({
        userId,
        modelId,
        range,
      })
    },
    async selectChartRange(chartRange) {
      if (chartRange.disabled) return
      this.selectedChartRange = chartRange
    },
    convertRawChartData(rawChartData) {
      this.chartOptions = {
        chart: {},
        legend: 'none',
        curveType: 'function',
        crosshair: {
          trigger: 'focus',
        },
        colors: ['#6DD400'],
        vAxis: {
          format: 'percent',
        },
      }
      const { performance } = rawChartData
      const chartDataPoints = performance.map((p) => {
        const dayOfWeek = moment(p.timestamp).format('ddd')
        return [
          dayOfWeek,
          {
            v: parseFloat(p.positionValuePercentage / 100),
            f: `${parseFloat(p.positionValuePercentage).toFixed(2)}%`,
          },
        ]
      })
      if (chartDataPoints.length === 0) {
        this.chartDataPoints = chartDataPoints
        this.loading = false
        return
      }
      this.chartDataPoints = [['Period', '']].concat(chartDataPoints)
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global';

.chart-range-item {
  display: flex;
  align-items: center;
  padding: 4px;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 20px;
  border: 1px solid white;
  border-radius: 4px;

  &.disabled {
    color: rgb(0, 0, 0, 0.3);
    cursor: default !important;
  }
  &.active {
    cursor: pointer;
    &.selected {
      border: 1px solid rgb(0, 0, 0, 0.15);
    }
  }
}

.chart-range-container {
  display: flex;
  flex-grow: 1;
}
</style>
