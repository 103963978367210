<template>
  <v-select
    :loading="loading"
    :items="models"
    label="Select Model"
    item-text="name"
    item-value="_id"
    outlined
    dense
    :value="selectedModelId"
    @change="onModelChange"
  ></v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      loading: true,
      selectedModelId: undefined,
    }
  },
  watch: {
    selectedModel(value) {
      this.selectedModelId = value?._id
    },
  },
  computed: {
    ...mapGetters({
      selectedModel: 'performance/getSelectedModel',
      models: 'model/getModels',
    }),
  },
  async mounted() {
    this.loading = true
    const model = this.models?.[0]
    this.setSelectedModel(model?._id)
    this.loading = false
  },
  methods: {
    ...mapActions({
      getModels: 'performance/getModels',
      setSelectedModel: 'performance/setSelectedModel',
    }),
    onModelChange(modelId) {
      this.setSelectedModel(modelId)
    },
  },
}
</script>

<style></style>
