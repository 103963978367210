<template>
  <v-card :loading="loading" fill-height flat class="wl-page-module">
    <v-card-title>Cost Basis</v-card-title>
    <v-card-text v-if="loading">
      <v-skeleton-loader type="table-tbody" />
    </v-card-text>
    <v-card-text v-else>
      <v-flex row>
        <v-flex xs1 />
        <v-flex xs11 row>
          <v-flex class="performance-data-container-border">
            <span class="performance-data-label">Cost</span>
            <span class="performance-data-value">{{ totalCost }}</span>
          </v-flex>
          <v-flex class="performance-data-container-border">
            <span class="performance-data-label">Current Value</span>
            <span class="performance-data-value">{{ currentValue }}</span>
          </v-flex>
          <v-flex class="performance-data-container-border">
            <span class="performance-data-label">Gains</span>
            <span class="performance-data-value">{{ gains }}</span>
          </v-flex>
          <v-flex class="performance-data-container">
            <span class="performance-data-label">Gains (%)</span>
            <span class="performance-data-value">{{ gainsPercentage }}</span>
          </v-flex>
        </v-flex>
        <v-flex xs12 class="performance-data-search">
          <v-text-field
            label="Search Positions"
            v-model="searchPositionsValue"
            outlined
            dense
            color="bobGreen"
            @keydown="initSearch"
          />
        </v-flex>
      </v-flex>
      <hr class="bar-footer" />

      <v-data-table
        :headers="headers"
        :items="op(modelPerformance, 'performance/holdingsValues') || []"
        :search="searchPositions"
        :page="page"
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatPercent } from '@/utils/number'
import currencyFilter from '@/utils/currency'
import { ModelPerformanceColumns } from './lib/columns'
import op from 'simple-object-path'
import { mapActions, mapGetters } from 'vuex'
import { authComputed } from '@/state/helper'

export default {
  computed: {
    ...mapGetters({
      selectedModel: 'performance/getSelectedModel',
      modelPerformance: 'performance/getModelPerformance',
      client: 'client/getClient',
    }),
    ...authComputed,
    totalCost() {
      if (!this.modelPerformance?.performance?.totalHoldingsCost) {
        return currencyFilter(0)
      }
      return currencyFilter(
        this.modelPerformance?.performance?.totalHoldingsCost
      )
    },
    currentValue() {
      if (!this.modelPerformance?.performance?.totalHoldingsCurrentValue) {
        return currencyFilter(0)
      }
      return currencyFilter(
        this.modelPerformance?.performance?.totalHoldingsCurrentValue
      )
    },
    gains() {
      if (!this.modelPerformance?.performance?.positionValueAmount) {
        return currencyFilter(0)
      }
      return currencyFilter(
        this.modelPerformance?.performance?.positionValueAmount
      )
    },
    gainsPercentage() {
      if (!this.modelPerformance?.performance?.positionValuePercentage) {
        return 0.0
      }
      return Number(
        this.modelPerformance?.performance?.positionValuePercentage
      ).toFixed(2)
    },
  },
  watch: {
    selectedModel() {
      this.getPerformance()
    },
    client() {
      this.getPerformance(this.client)
    },
  },
  data() {
    return {
      op,
      formatPercent,
      currencyFilter,
      positions: [],
      headers: ModelPerformanceColumns,
      searchPositionsValue: undefined,
      searchPositions: undefined,
      page: 1,
      loading: false,
    }
  },
  methods: {
    ...mapActions({
      getModelPerformance: 'performance/getModelPerformance',
    }),
    async getPerformance(client) {
      let userId = this.currentUser?._id
      if (client) {
        userId = client.id
      }
      this.loading = true
      const modelId = this.selectedModel?._id
      await this.getModelPerformance({
        userId,
        modelId,
      })
      this.loading = false
    },
    initSearch($event) {
      this.debounce = setTimeout(() => {
        this.searchPositions = this.searchPositionsValue
        this.page = 1
      }, 1000)
      if ($event.key === 'Enter') {
        this.searchPositions = this.searchPositionsValue
        this.page = 1
        clearTimeout(this.debounce)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.performance-data-container-border {
  display: flex;
  align-items: center;
  margin: 10px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 20px;
  border-right: 1px solid rgb(0, 0, 0, 0.2);
}

.performance-data-container {
  display: flex;
  align-items: center;
  margin: 10px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.performance-data-search {
  margin: 10px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 20px;
  max-height: 40px;
}

.performance-data-value {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.performance-data-label {
  padding-right: 6px;
}
.bar-footer {
  opacity: 0.15;
  width: 100%;
}
</style>
