<template>
  <v-layout row wrap>
    <v-progress-linear
      v-show="loading"
      color="#0cb0c8"
      height="2"
      indeterminate
    />
    <v-flex xs12 v-if="!loading">
      <PicksCarousel
        label="Top Performers"
        :picks="topPerformers.map((p) => ({ ...p, symbol: p.id }))"
      />
    </v-flex>
    <v-flex xs12 v-if="!loading" class="picks-row">
      <PicksCarousel label="Your Picks" :picks="picks" />
    </v-flex>
    <v-flex xs12 md8 lg8 v-if="!loading" class="news-feed">
      <NewsFeed />
    </v-flex>
    <v-flex xs4 v-if="!loading" class="picks-side-panel">
      <TopPerformers :picks="picks" />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'
import PicksCarousel from '@/components/PicksCarousel'
import NewsFeed from '@/components/NewsFeed'
import TopPerformers from '@/components/TopPerformers'
export default {
  components: {
    PicksCarousel,
    NewsFeed,
    TopPerformers,
  },
  metaInfo: {
    title: `Bob - Home`,
  },
  data() {
    return {
      currentPage: 1,
      loading: true,
      picks: [],
      topPerformers: [],
    }
  },
  async mounted() {
    this.loading = true
    await this.getSymbols()
    this.picks = await this.getPicks()
    this.topPerformers = await this.getTopPerformers()
    this.loading = false
  },
  methods: {
    ...mapActions({
      getPicks: 'picks/getPicks',
      getSymbols: 'symbol/getSymbols',
      getTopPerformers: 'picks/getTopPerformers',
    }),
  },
}
</script>

<style lang="scss" scoped>
.picks-side-panel {
  display: block;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

.picks-row {
  display: block;
  @media only screen and (min-width: 960px) {
    display: none;
  }
}
</style>
