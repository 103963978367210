<template>
  <router-view />
</template>
<script>
export default {
  metaInfo: {
    title: 'Bob',
  },
}
</script>
