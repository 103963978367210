<template>
  <v-card :loading="loading" fill-height flat class="wl-page-module">
    <v-card-title>Clients</v-card-title>
    <v-card-text v-if="loading">
      <v-skeleton-loader type="table-tbody" />
    </v-card-text>
    <v-card-text v-else>
      <v-text-field
        label="Search Clients"
        v-model="searchClientsValue"
        outlined
        color="bobGreen"
        @keydown="initSearch"
      />
      <v-data-table
        :headers="headers"
        :items="clients"
        :search="searchClients"
        :page="page"
        :expanded="expanded"
        @click:row="toggleRowExpansion"
      >
        <template v-slot:item.cashAllocation="{ item }">
          <div class="cash-allocation-container">
            <v-text-field
              outlined
              dense
              v-model="item.newCashAllocation"
              prepend-inner-icon="mdi-currency-usd"
              color="bobGreen"
              tabindex="1"
              :ref="`cash_input_${clients.findIndex((f) => f === item)}`"
              @click="
                (e) =>
                  setFocus(
                    e,
                    clients.findIndex((f) => f === item)
                  )
              "
              @keydown="guardNumber"
            ></v-text-field>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn fab icon @click="() => item">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td class="expanded-client" :colspan="headers.length">
            <ClientManagementDetail :client="item" />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatPercent } from '@/utils/number'
import currencyFilter from '@/utils/currency'
import { ClientManagementTableColumns } from './lib/columns'
import op from 'simple-object-path'
import { mapActions, mapGetters } from 'vuex'
import ClientManagementDetail from './ClientManagementDetail'

export default {
  components: {
    ClientManagementDetail,
  },
  metaInfo: {
    title: 'Bob - Client Management',
  },
  computed: {
    ...mapGetters({
      managedClients: 'client/getManagedClients',
    }),
  },
  watch: {
    managedClients() {
      this.clients = this.managedClients.map((m) => ({
        ...m,
        newCashAllocation: m.cashAllocation || m.cash,
        actions: '',
      }))
    },
  },
  data() {
    return {
      op,
      formatPercent,
      currencyFilter,
      positions: [],
      headers: ClientManagementTableColumns,
      searchClientsValue: undefined,
      searchClients: undefined,
      page: 1,
      clientIndex: 0,
      loading: false,
      clients: [],
      expanded: [],
    }
  },
  async mounted() {
    this.loading = true
    await this.getManagedClients()
    this.loading = false
  },
  methods: {
    ...mapActions({
      getManagedClients: 'client/getManagedClients',
    }),
    initSearch($event) {
      this.debounce = setTimeout(() => {
        this.searchClients = this.searchClientsValue
        this.page = 1
      }, 1000)
      if ($event.key === 'Enter') {
        this.searchClients = this.searchClientsValue
        this.page = 1
        clearTimeout(this.debounce)
      }
    },
    toggleRowExpansion(item) {
      if (this.expanded.includes(item)) {
        this.expanded = this.expanded.filter((i) => i !== item)

        return
      }
      this.expanded = [].concat(this.expanded, [item])
    },
    guardNumber(e) {
      const code = e?.code ? e?.code : ''
      const validdKeys = ['Backspace', 'ArrowRight', 'ArrowLeft', 'Period']
      if (code === 'Tab') {
        this.clientIndex++
        if (!this.$refs[`cash_input_${this.clientIndex}`]) {
          this.clientIndex = 0
        }
        this.$refs[`cash_input_${this.clientIndex}`].focus()
      }
      if (
        !(code.startsWith('Digit') || validdKeys.includes(code)) ||
        e.shiftKey
      ) {
        e.preventDefault()
      }
    },
    setFocus(e, index) {
      e.stopPropagation()
      this.clientIndex = index
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.cash-allocation-container {
  margin-top: 10px;
  margin-bottom: -20px;
}
.expanded-row {
  margin-top: 6px;
  margin-bottom: 6px;
}
.expanded-client {
  background-color: white;
}
</style>
