<template>
  <v-flex layout row wrap>
    <v-flex
      v-for="model of models"
      :key="`models_list_item_${model._id}`"
      xs12
      row
      wrap
      class="model-container"
    >
      <v-flex class="model-top-detail-container" xs12 row>
        <v-flex xs6>
          <div>
            <h3>{{ model.name }}</h3>
          </div>
          <div>{{ model.subtitle }}</div>
        </v-flex>
        <v-spacer />
        <v-flex>
          <div class="model-logo-container">
            <img
              :src="model.logo"
              class="model-logo"
              @click="$router.push({ name: 'advisorHome' })"
            />
          </div>
        </v-flex>
      </v-flex>
      <v-flex xs12 md6 lg6 class="model-action-container left">
        <v-btn text small color="#0cb0c8">Join</v-btn>
      </v-flex>
      <v-flex xs12 md6 lg6 class="model-action-container ">
        <v-btn text small color="#0cb0c8">Request Demo</v-btn>
      </v-flex>
    </v-flex>
  </v-flex>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  metaInfo: {
    title: 'Bob - Models',
  },
  computed: {
    ...mapGetters({
      models: 'model/getModels',
    }),
  },
}
</script>

<style lang="scss" scoped>
.model-logo-container {
  float: right;
  display: flex;
  align-items: center;
}

.model-logo {
  height: 36px;
}
.model-container {
  border: 1px solid rgb(211, 211, 211, 0.5);
  margin-right: 20px;
  border-radius: 6px;
  margin: 20px;
}

.model-top-detail-container {
  min-height: 100px;
  padding: 20px;
}

.model-action-container {
  cursor: pointer;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-top: 1px solid rgb(211, 211, 211, 0.5);

  &.left {
    @media only screen and (max-width: 960px) {
      border-right: none;
    }
    border-right: 1px solid rgb(211, 211, 211, 0.5);
  }
}
</style>
