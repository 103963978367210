<template>
  <v-flex class="news-item" row @click="showNews">
    <v-flex xs12 row>
      <v-flex xs12 class="news-source">
        <div>{{ newsItem.source }}</div>
        <v-spacer />
        <div>
          {{ getTime(newsItem) }}
        </div>
      </v-flex>
      <v-flex xs12>
        <v-img class="news-image white--text align-end" :src="newsItem.image">
          <div class="news-headline-container">
            <v-card-title class="news-headline">
              <div>
                <v-icon
                  v-if="newsItem.newsSubType === 'Video'"
                  large
                  color="white"
                >
                  mdi-play
                </v-icon>
                {{ newsItem.headline }}
              </div>
            </v-card-title>
          </div>
        </v-img>
      </v-flex>
    </v-flex>
  </v-flex>
</template>

<script>
import moment from 'moment-timezone'
export default {
  props: {
    newsItem: { type: Object, default: () => {} },
  },
  methods: {
    trimmedBody(body) {
      if (body.length === 0) {
        return ''
      }
      return body.length < 400 ? body : body.substring(0, 400) + ' ...'
    },
    showNews() {
      window.open(this.newsItem?.newsUrl)
    },
    getTime(newsItem) {
      const now = moment()
      const newsTime = moment(newsItem.timestamp)
      const minutes = now.diff(newsTime, 'minutes')
      const hours = now.diff(newsTime, 'hours')
      const days = now.diff(newsTime, 'days')
      if (minutes > 59) {
        if (hours > 23) {
          return `${days}d`
        } else {
          return `${hours}h`
        }
      } else {
        return `${minutes}m`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.news-item {
  cursor: pointer;
  padding: 30px;
  border-bottom: 1px solid rgba(207, 203, 203, 0.5);
}

.news-image {
  width: 44vw;
  border-radius: 4px;
}

.news-headline-container {
  width: 100%;
  max-height: 300px;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1))
    right / 60%;

  @media only screen and (max-width: 1200px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

.news-headline {
  color: white;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-bottom: 0px;
  word-break: normal;
  @media only screen and (max-width: 1200px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}
.news-source {
  display: flex;
  padding-left: 12px;

  @media only screen and (max-width: 1200px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
  }
  color: #10161c;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 10px;
}
.news-body {
  @media only screen and (max-width: 1400px) {
    display: none;
  }
  color: #959ea9;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.news-detail-container {
  padding-left: 40px;
}
</style>
