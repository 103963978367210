import axios from 'axios'

export const namespaced = true

export const state = {
  payment: undefined,
}

export const getters = {
  getPayment() {
    return state.payment
  },
}

export const mutations = {
  setPayment(state, payment) {
    state.payment = payment
  },
}

export const actions = {
  async getPayment({ commit }) {
    const paymentResult = await axios
      .get(`/v0/payment`)
      .catch((e) => e.response)
    commit('setPayment', paymentResult.data)
    return paymentResult.data
  },
  async savePayment({ commit }, cardInfo) {
    const paymentResult = await axios
      .post(`/v0/createPayment`, cardInfo)
      .catch((e) => e.response)
    commit('setPayment', paymentResult.data)
    return paymentResult.data
  },
}
