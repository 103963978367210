<template>
  <div>
    <v-card :loading="loadingBrokerDetail" flat class="wl-page-module">
      <v-card-title>
        Brokerage
      </v-card-title>
      <v-card-text>
        <v-flex v-if="linkedBroker && !loadingBrokerDetail">
          <v-alert outlined type="success" text>
            <strong>{{ linkedBroker.brokerName }}</strong>
            linked.
            <v-btn outlined color="success" @click="redirectToTDA">
              Update
            </v-btn>
          </v-alert>
        </v-flex>
        <v-flex v-if="!linkedBroker && !loadingBrokerDetail">
          <v-alert outlined type="error" text>
            No linked broker found.
            <v-btn dense outlined color="error" @click="redirectToTDA">
              Link Brokerage
            </v-btn>
          </v-alert>
        </v-flex>
      </v-card-text>
    </v-card>

    <v-card :loading="loadingPaymentDetail" flat class="wl-page-module">
      <v-card-title>
        Payment
      </v-card-title>
      <v-card-text>
        <v-flex v-if="linkedPayment && !loadingPaymentDetail">
          <v-alert outlined type="success" text>
            <strong>{{ linkedPayment.brand }}</strong>
            ending in
            <strong>{{ linkedPayment.last4 }}</strong>
            last updated on
            <strong>
              {{ moment(linkedPayment.createdDate).format('L') }}
            </strong>
            .
            <v-btn
              outlined
              color="success"
              @click="paymentOverlay = !paymentOverlay"
            >
              Update
            </v-btn>
          </v-alert>
        </v-flex>
        <v-flex v-if="!linkedPayment && !loadingPaymentDetail">
          <v-alert outlined type="error" text>
            No payment added.
            <v-btn
              dense
              outlined
              color="error"
              @click="paymentOverlay = !paymentOverlay"
            >
              Link Payment
            </v-btn>
          </v-alert>
        </v-flex>
        <v-overlay :dark="true" :value="paymentOverlay">
          <CreditCardForm @close="closePaymentForm" @saved="savedPayment" />
        </v-overlay>
        <v-overlay :dark="true" :value="showAccountOverlay">
          <AccountNumberForm @saved="linkComplete" />
        </v-overlay>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CreditCardForm from '@/components/CreditCardForm'
import AccountNumberForm from '@/components/AccountNumberForm'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
export default {
  components: {
    CreditCardForm,
    AccountNumberForm,
  },
  data() {
    return {
      moment,
      linkedBroker: undefined,
      linkedPayment: undefined,
      paymentOverlay: false,
      loadingPaymentDetail: false,
      loadingBrokerDetail: false,
      accountNumberComplete: false,
    }
  },
  computed: {
    ...mapGetters({
      successfulLink: 'broker/getSuccessfulLink',
    }),
    showAccountOverlay() {
      return this.successfulLink && !this.accountNumberComplete
    },
  },
  async mounted() {
    await this.getPaymentDetail()
    await this.getBrokerDetail()
  },
  methods: {
    ...mapActions({
      getPayment: 'payment/getPayment',
      getBroker: 'broker/getBroker',
    }),
    ...mapMutations({
      setCallbackRoute: 'broker/setCallbackRoute',
    }),
    async getPaymentDetail() {
      this.loadingPaymentDetail = true
      this.linkedPayment = (await this.getPayment()).payment
      this.loadingPaymentDetail = false
    },
    async getBrokerDetail() {
      this.loadingBrokerDetail = true
      this.linkedBroker = await this.getBroker()
      this.loadingBrokerDetail = false
    },
    async closePaymentForm() {
      await this.getPaymentDetail()
      this.paymentOverlay = false
    },
    async savedPayment() {
      await this.getPaymentDetail()
      this.paymentOverlay = false
    },
    async linkComplete() {
      await this.getBrokerDetail()
      this.accountNumberComplete = true
    },
    redirectToTDA() {
      this.setCallbackRoute('clientAccount')
      window.location.href =
        'https://auth.tdameritrade.com/oauth?client_id=GZJ81YCGYAOFOXSPXBIGXF6CCN4BWITE%40AMER.OAUTHAP&response_type=code&redirect_uri=https%3A%2F%2Fapp.beta.letbob.com%2Fbroker%2Fcallback'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.v-overlay__content {
  text-align: center;
}
</style>
