<template>
  <div>
    <h1>OOPS Somethings broken</h1>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: `Bob - Oops!`,
  },
}
</script>

<style></style>
