import axios from 'axios'

export const namespaced = true

export const state = {
  broker: undefined,
  successfulLink: false,
  code: undefined,
  token: undefined,
  callbackRoute: undefined,
}

export const getters = {
  getBroker() {
    return state.broker
  },
  getSuccessfulLink() {
    return state.successfulLink
  },
  getCode() {
    return state.code
  },
  getCallbackRoute() {
    return localStorage.getItem('callbackRoute')
  },
}

export const mutations = {
  setBroker(state, broker) {
    state.broker = broker
  },
  setSuccessfulLink(state, successfulLink) {
    state.successfulLink = successfulLink
  },
  setCode(state, code) {
    state.code = code
  },
  setToken(state, token) {
    state.token = token
  },
  setCallbackRoute(_, callbackRoute) {
    localStorage.setItem('callbackRoute', callbackRoute)
  },
}

export const actions = {
  async getBroker({ commit }) {
    const brokerResult = await axios
      .get(`/v0/getBroker`)
      .catch((e) => e.response)
    commit('setBroker', brokerResult.data?.broker)
    return brokerResult.data?.broker
  },
  async updateAccountNumber(_, accountNumPayload) {
    return await axios.post(`/v0/updateAccount`, accountNumPayload)
  },
  async getToken({ commit }, code) {
    const result = await axios.get(`/v0/getTdAmeritradeToken/${code}`)
    console.log('token result', result)
    commit('setToken', result)
  },
}
