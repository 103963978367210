<template>
  <v-flex class="news-item" row @click="showNews">
    <v-flex xs6>
      <v-hover>
        <template
          v-slot:default="{
            hover,
          }"
        >
          <v-card flat>
            <img :src="newsItem.image" class="news-image" />
            <v-overlay
              v-if="newsItem.newsSubType === 'Video' ? true : hover"
              absolute
              style="width: 101%; height: 97%"
              :opacity="0.2"
            >
              <v-icon
                v-if="newsItem.newsSubType === 'Video'"
                large
                color="white"
              >
                mdi-play
              </v-icon>
              <span
                style="font-weight: 500"
                v-if="newsItem.newsSubType === 'Video'"
              >
                Click to play
              </span>
              <span
                style="font-weight: 500"
                v-if="newsItem.newsSubType === 'Article'"
              >
                Click to read
              </span>
            </v-overlay>
          </v-card>
        </template>
      </v-hover>
      <v-subheader>
        <small>{{ newsItem.related.join(' ') }}</small>
      </v-subheader>
    </v-flex>
    <v-flex xs6 class="news-detail-container">
      <div class="news-source">
        {{ newsItem.source }}
        <v-spacer />
        <div class="news-time">
          {{ getTime(newsItem) }}
        </div>
      </div>
      <div class="news-headline">{{ newsItem.headline }}</div>
      <div class="news-body">{{ trimmedBody(newsItem.body) }}</div>
    </v-flex>
  </v-flex>
</template>

<script>
import moment from 'moment-timezone'
export default {
  props: {
    newsItem: { type: Object, default: () => {} },
  },
  methods: {
    trimmedBody(body) {
      if (body.length === 0) {
        return ''
      }
      return body.length < 400 ? body : body.substring(0, 400) + ' ...'
    },
    showNews() {
      window.open(this.newsItem?.newsUrl)
    },
    getTime(newsItem) {
      const now = moment()
      const newsTime = moment(newsItem.timestamp)
      const minutes = now.diff(newsTime, 'minutes')
      const hours = now.diff(newsTime, 'hours')
      const days = now.diff(newsTime, 'days')
      if (minutes > 59) {
        if (hours > 23) {
          return `${days}d`
        } else {
          return `${hours}h`
        }
      } else {
        return `${minutes}m`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.news-item {
  cursor: pointer;
  padding: 20px;
  border-bottom: 1px solid rgba(207, 203, 203, 0.5);
}

.news-image {
  width: 22vw;
  border-radius: 4px;
}

.news-headline {
  color: #10161c;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 32px;
  margin-bottom: 10px;
  @media only screen and (max-width: 1200px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}
.news-source {
  display: flex;
  @media only screen and (max-width: 1200px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
  }
  color: #10161c;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin-bottom: 10px;
}
.news-body {
  @media only screen and (max-width: 1400px) {
    display: none;
  }
  color: #959ea9;
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 24px;
}

.news-detail-container {
  padding-left: 20px;
  padding-right: 20px;
}

.news-time {
  margin-right: 4px;
}
</style>
