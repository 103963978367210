<template>
  <v-card :loading="!client" flat class="wl-page-module">
    <v-card-title>
      <div>
        {{ client.name }}
      </div>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels flat>
        <v-expansion-panel flat expand v-model="panel" class="item-panel">
          <v-expansion-panel-header>
            <h3>Withdraw Cash</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="item-panel-detail">
            <v-flex row wrap>
              <v-flex xs3>
                <v-text-field
                  outlined
                  dense
                  color="bobGreen"
                  type="text"
                  class="withdrawal-input"
                  prepend-inner-icon="mdi-currency-usd"
                  placeholder="5000.00"
                  v-model="withdrawalAmount"
                  @keydown="guardNumber"
                ></v-text-field>
              </v-flex>
              <v-spacer />
              <v-flex xs2 class="wl-centered">
                <v-btn color="bobGreen" outlined>Withdraw</v-btn>
              </v-flex>
              <v-spacer />
              <v-flex xs6 class="withdrawal-disclaimer">
                This will initiate a sell of securities held within models
                managed by Bob. The funds will be available in the client
                account once settled.
              </v-flex>
            </v-flex>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  components: {},
  props: {
    client: { type: Object, default: () => undefined },
  },
  data() {
    return {
      withdrawalAmount: undefined,
      panel: [0],
    }
  },
  async mounted() {
    console.log('mounted')
    await this.getClientById(this.client?.id)
  },
  methods: {
    ...mapActions({
      getClientById: 'client/getClientById',
    }),
    guardNumber(e) {
      const code = e?.code ? e?.code : ''
      const validdKeys = ['Backspace', 'ArrowRight', 'ArrowLeft', 'Period']

      if (
        !(code.startsWith('Digit') || validdKeys.includes(code)) ||
        e.shiftKey
      ) {
        e.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.item-panel {
  border: 1px solid rgb(0, 0, 0, 0.2);
}
.item-panel-detail {
  border-top: 1px solid rgb(0, 0, 0, 0.2);
  padding-top: 20px;
  padding-bottom: 10px;
}
.withdrawal-input {
  color: $bobGreen;
}
.withdrawal-disclaimer {
  color: #959ea9;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 24px;
}
</style>
