<template>
  <div>
    <div class="picks-header">{{label}}</div>
    <div class="picks-nav-container">
      <div
        class="picks-nav-button"
        role="button"
        @click="backward"
        aria-roledescription="previous pick"
      >
        <v-icon large>mdi-menu-left</v-icon>
      </div>
      <div :id="`picks-container`" class="picks-container">
        <div
          class="pick-container"
          v-for="(pick, pickIdx) of topPerformers"
          :key="`my-picks-${pickIdx}`"
          :id="`pick-container-${label.replace(' ', '')}-${pickIdx}`"
          @mouseenter="() => hoverItem(pickIdx)"
        >
          <PickContainer :pick="pick" />
        </div>
      </div>
      <div
        class="picks-nav-button"
        role="button"
        @click="forward"
        aria-roledescription="next pick"
      >
        <v-icon large>mdi-menu-right</v-icon>
      </div>
    </div>
    <hr class="bar-footer" />
  </div>
</template>

<script>
import PickContainer from './PickContainer'

export default {
  components: {
    PickContainer,
  },
  props: {
    picks: { type: Array, default: () => [] },
    label: { type: String, default: '' },
  },
  data() {
    return {
      loading: false,
      scrollItem: 0,
      topPerformers: [],
    }
  },
  watch: {
    scrollItem() {
      const item = document.querySelector(`#pick-container-${this.label.replace(' ', '')}-${this.scrollItem}`)
      item.scrollIntoView({
        block: 'center',
        inline: 'center',
        behavior: 'smooth',
      })
    },
  },
  mounted() {
    this.topPerformers = this.picks
  },
  methods: {
    hoverItem(scrollItem) {
      if (this.hoverDebounce) clearTimeout(this.hoverDebounce)
      this.hoverDebounce = setTimeout(() => {
        this.scrollItem = scrollItem
      }, 500)
    },
    forward() {
      if (this.scrollItem === this.picks.length - 1) {
        this.scrollItem = 0
      } else {
        this.scrollItem++
      }
    },
    backward() {
      if (this.scrollItem - 1 === -1) {
        this.scrollItem = 0
      } else {
        this.scrollItem--
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.picks-header {
  margin-top: 40px;
  padding-left: 8px;
  color: #10161c;
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 32px;
}

.picks-nav-button {
  display: flex;
  align-items: center;
}

.bar-footer {
  opacity: 0.15;
  width: 100%;
  @media only screen and (max-width: 600px) {
    width: 100vw;
  }
}

.picks-nav-container {
  display: flex;
}

.pick-container {
  @media only screen and (max-width: 600px) {
    width: 300px;
  }
  width: 400px;
  margin: 10px;
  padding: 20px;
  flex-shrink: 0;
  height: 100px;
  background: linear-gradient(to top, lightgray, white) right / 1px 80%, white;
  background-repeat: no-repeat;
}
.picks-container {
  width: 100%;
  height: 120px;
  display: flex;
  overflow-x: auto;
}
</style>
